<template>
  <div class="all">
    <div class="background">
      <img v-if="this.$store.getters.enterpriseObj" class="logo" :src="this.$store.getters.enterpriseObj.config.loginBackground" style="width: 100%;height: 100%;"
        alt="" />
      <img v-else src="../../assets/login/bg-login.png" style="width: 100%;height: 100%;" alt="" />
    </div>
    <div class="content">
      <img :src="$store.getters.enterpriseObj.config.homeLogo">
      <div class="content-login">
        <div class="content-login-header" @click="toBack">
          <img src="../../assets/login/icon-arrowL-24.png">
          <h3>完善个人信息</h3>
        </div>
        <div class="content-tou">
          <!--              <img v-if="this.userBuddha" :src="this.userBuddha" class="avatar">-->
          <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          <img v-if="this.genders == '男'" src="../../assets/login/默认头像-男.png" class="avatar">
          <img v-else src="../../assets/login/默认头像-女.png" class="avatar">
          <!--          <el-upload-->
          <!--              style=""-->
          <!--              class="avatar-uploader"-->
          <!--              action=""-->
          <!--              :show-file-list="false"-->
          <!--              :on-success="handleAvatarSuccess"-->
          <!--              :before-upload="beforeAvatarUpload">-->
          <!--            <div style="display: flex">-->
          <!--&lt;!&ndash;              <img v-if="this.userBuddha" :src="this.userBuddha" class="avatar">&ndash;&gt;-->
          <!--&lt;!&ndash;              <i v-else class="el-icon-plus avatar-uploader-icon"></i>&ndash;&gt;-->
          <!--              <img :src="this.userBuddha" class="avatar">-->
          <!--              <div style="text-align: left; margin-left: 10px;padding-top: 10px">-->
          <!--                <a style="font-size: 12px;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #1371E7;">点击上传</a>-->
          <!--                <div slot="tip" class="el-upload__tip">支持jpg、 jpeg或png格式</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </el-upload>-->
        </div>
        <el-form label-position="top" :rules="rules" label-width="680px" :inline="true" ref="form" :model="form">
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="姓名" prop="name">
            <el-input disabled v-model="form.name" clearable placeholder="请输入真实姓名" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="职业类别" prop="occupationType">
            <el-input v-model="form.occupationType" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="性别" prop="gender">
            <el-radio disabled v-model="genders" label="男">男</el-radio>
            <el-radio disabled v-model="genders" label="女">女</el-radio>
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="职称" prop="jobTitle">
            <el-input v-model="form.jobTitle" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="证件号码" prop="certificateNo">
            <el-input disabled v-model="form.certificateNo" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="职务" prop="duty">
            <el-input v-model="form.duty" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;padding: 0;text-align: left;margin-bottom: 6px" label="邮箱号码" prop="email">
            <el-input v-model="email" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 6px" label="技术级别" prop="technologyLevel">
            <el-input v-model="form.technologyLevel" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;padding: 0;text-align: left;margin-bottom: 15px" label="工作单位" prop="workUnit">
            <el-input v-model="form.workUnit" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
          <el-form-item style="width: 282px;text-align: left;margin-bottom: 15px" label="专业级别" prop="professionalLevel">
            <el-input v-model="form.professionalLevel" clearable placeholder="请输入" maxlength="40" />
          </el-form-item>
        </el-form>
        <div class="bottom-all">
          <span class="btn" style="cursor: pointer;" @click="getEnrollren('form')">注册</span>
          <!-- <div class="login-toenroll">
            已经有账户?
            <span style="cursor: pointer;" @click="goLogin()">登录</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpPost } from "@/utils/httpRequest";
import { httpNPGet } from "../../utils/httpRequest";

export default {
  name: "login",
  data() {
    return {
      input1: '',
      input2: '',
      checked: false,
      imageUrl: '',
      radio: '2',
      genders: '男',
      form: {
        name: '',
        gender: '',
        occupationType: '',
        jobTitle: '',
        certificateNo: '',
        duty: '',
        technologyLevel: '',
        workUnit: '',
        professionalLevel: '',
        userId: '',
      },
      email: '',
      userBuddha: '../../assets/login/默认头像-男.png',
      rules: {
        name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        certificateNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
        ],
      },
      enrollrenDate: [],
    }
  },
  mounted() {
    // console.log('完善个人信息的id-----', localStorage.getItem('userId'))
    // console.log(this.$route.params.messageForm)
    this.form.name = this.$route.params.messageForm.name
    this.genders = this.$route.params.messageForm.sex
    this.form.certificateNo = this.$route.params.messageForm.idNumber
    this.changeForm()
    // this.getUserId()

    
    Object.assign(this.form, this.$route.params.messageForm)
    // console.log(this.form)
    this.form.nation = this.form.ethnicity
    this.form.termOfValidity = this.form.validPeriod
    // console.log(this.form.birthDate);
    // console.log(this.form.birthDate.substring(0,4));
    if (this.form.birthDate && this.form.birthDate.length > 4) {
      this.form.age = new Date().getFullYear() - parseInt(this.form.birthDate.substring(0,4));
    }
    delete this.form.birthDate;
    delete this.form.ethnicity
    delete this.form.id
    delete this.form.idNumber
    delete this.form.issueAuthority
    delete this.form.sex
    delete this.form.userId
    delete this.form.validPeriod
  },
  methods: {
    // 表单继承
    changeForm(){

    },
    // 获取当前登录的用户id
    getUserId() {
      httpNPGet("/app/app-user/get/identity-id", (res) => {
        // this.form.userId = res.data.identityId
        this.form.userId = " "
        // console.log(this.identityId)
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log('----URL.createObjectURL(file.raw)----', URL)
    },
    beforeAvatarUpload(file) {
      // console.log('----file.type----', file.size / 1024 / 1024 < 2)
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 image/png 或 image/jpeg 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    getEnrollren(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.genders === '男') {
            this.form.gender = '男';
          } else {
            this.form.gender = '女';
          }
          // 头像·上传
          // httpPost("/app/app-user-identity/update",
          // {
          //   userId: localStorage.getItem('userId'),
          //   email: this.email
          // }
          // , (res) => {
          //   console.log('----------res.data---------', res.data)
          // }, (err) => {
          //   // console.log(err)
          //   throw err
          // })
          // console.log(this.form)
          this.form.id = this.form.idNumber
          httpPost("/app/app-user-identity/update",
            this.form
            , (res) => {
              this.enrollrenDate = res.data
              this.$router.push('/')
              this.$message.success("信息已完善")
            }, (err) => {
              if (err.code == 500) {
                if (err.msg == "token异常，请重新登录") {
                  localStorage.clear();
                  if (!localStorage.getItem("userId")) {
                    this.$message.error('登录过期，请重新登录!')
                    this.$router.push("/login")
                  }
                }
              }
              // // console.log(err)
              throw err
            })
        } else {
          return false;
        }
      });
    },
    // 页面跳转
    goLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push('/login')
        } else {
          return false;
        }
      });
    },
    toBack() {
      this.$router.push("authentication")
    }
  }
}
</script>

<style lang="scss" scoped>
.all {

  .background {
    width: 100%;
    height: 100%;
    /**宽高100%是为了图片铺满屏幕 **/
    z-index: -1;
    position: absolute;
  }

  .content {
    text-align: center;
    width: 680px;
    height: 764px;
    //box-sizing: border-box;
    //background-color: #fff;
    z-index: 1;
    position: absolute;
    top: -80px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    .content-login {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      //align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding: 20px 48px 10px 48px;
      //margin-top: 25px;
      background: #FFF;
      //box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.01), 0px 4px 11px 0px rgba(0, 0, 0, 0.02), 0px 10px 27px 0px rgba(0, 0, 0, 0.03);
      border-radius: 20px;

      .content-login-header {
        height: 40px;
        line-height: 40px;
        display: flex;
        padding-bottom: 20px;
        align-items: center;
        cursor: pointer;
        //justify-content: space-between;

        h3 {
          font-size: 28px;
          padding: 0 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
        }

        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }

      .content-tou {

        .avatar-uploader {
          display: flex;
        }

        .avatar-uploader .el-upload {
          //border: 1px dashed #d9d9d9;
          cursor: pointer;
          position: relative;
          border-radius: 2px 2px 2px 2px;
          overflow: hidden;
          //border: 1px dashed red;

        }

        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }

        .avatar-uploader-icon {
          border: 1px dashed #d9d9d9;
          font-size: 20px;
          color: #8c939d;
          width: 62px;
          height: 62px;
          line-height: 62px;
          text-align: center;
        }

        .avatar {
          width: 62px;
          height: 62px;
          display: block;
        }
      }

      .bottom-all {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .login-toenroll {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;

        span {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1371E7;
        }
      }

      .btn {
        display: block;
        width: 339px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #1371E7;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
        border-radius: 2px 2px 2px 2px;
        margin-bottom: 18px;
      }
    }
  }
}
</style>